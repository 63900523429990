// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
// baseUrl: 'http://192.168.10.11:3000' // staging
//baseUrl: 'http://192.168.10.4:3000' // staging

//baseUrl: 'http://192.168.10.11:3000' // staging



//USE THIS
 baseUrl: 'https://admin.jeptags.com' // staging

 //baseUrl:'http://192.168.10.3:3000'

  //baseUrl: 'http://168.63.140.202:8001/instamunch/' // staging
  //baseUrl: 'http://51.11.55.5:8000/instamunch/' // production
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
